import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';


import {CommonService} from '../../../../_services/common.service';

@Injectable()
export class StudentsAnswersService {

  private _url: string = this.commonService._hostName + 'admin-api-v1/students_answers.php?action=';

  constructor(public http: HttpClient, public commonService: CommonService) {
  }

  getSomeStudentsAnswers(
    start,
    aItemsPerPage,
    sort,
    type,
    searchId,
    searchName,
    searchStatus,
    searchDateFrom,
    searchDateTo,
    searchCountry,
    searchStage,
    searchGrade,
    searchSubject,
    searchTestSemester,
    searchUnit,
    searchLesson,
    searchUserId,
  userType) {
    return this.http.get<any>(
      this._url + 'getSomeStudentsAnswers&start=' + start +
      '&aItemsPerPage=' + aItemsPerPage +
      '&sort=' + sort +
      '&type=' + type +
      '&searchId=' + searchId +
      '&searchName=' + searchName +
      '&searchStatus=' + searchStatus +
      '&searchDateFrom=' + searchDateFrom +
      '&searchDateTo=' + searchDateTo +
      '&searchCountry=' + searchCountry +
      '&searchStage=' + searchStage +
      '&searchGrade=' + searchGrade +
      '&searchSubject=' + searchSubject +
      '&searchTestSemester=' + searchTestSemester +
      '&searchUnit=' + searchUnit +
      '&searchLesson=' + searchLesson+
      '&searchUserId=' + searchUserId+
      '&userType=' + userType
    );
  }

  getSearchStudentsAnswersCount(
    sort,
    type,
    searchId,
    searchName,
    searchStatus,
    searchDateFrom,
    searchDateTo,
    searchCountry,
    searchStage,
    searchGrade,
    searchSubject,
    searchTestSemester,
    searchUnit,
    searchLesson,
    searchUserId,
    userType
  ) {
    return this.http.get<any>(
      this._url
      + 'getSearchStudentsAnswersCount&sort=' + sort +
      '&type=' + type +
      '&searchId=' + searchId +
      '&searchName=' + searchName +
      '&searchStatus=' + searchStatus +
      '&searchDateFrom=' + searchDateFrom +
      '&searchDateTo=' + searchDateTo +
      '&searchCountry=' + searchCountry +
      '&searchStage=' + searchStage +
      '&searchGrade=' + searchGrade +
      '&searchSubject=' + searchSubject +
      '&searchTestSemester=' + searchTestSemester +
      '&searchUnit=' + searchUnit +
      '&searchLesson=' + searchLesson+
      '&searchUserId=' + searchUserId+
      '&userType=' + userType);
  }

  getStudentsAnswersCount(
    searchId,
    searchName,
    searchStatus,
    searchDateFrom,
    searchDateTo,
    searchCountry,
    searchStage,
    searchGrade,
    searchSubject,
    searchTestSemester,
    searchUnit,
    searchLesson,
    searchUserId,
    userType
  ) {
    return this.http.get<any>(this._url + 'getStudentsAnswersCount&searchId=' + searchId +
      '&searchName=' + searchName +
      '&searchStatus=' + searchStatus +
      '&searchDateFrom=' + searchDateFrom +
      '&searchDateTo=' + searchDateTo +
      '&searchCountry=' + searchCountry +
      '&searchStage=' + searchStage +
      '&searchGrade=' + searchGrade +
      '&searchSubject=' + searchSubject +
      '&searchTestSemester=' + searchTestSemester +
      '&searchUnit=' + searchUnit +
      '&searchLesson=' + searchLesson+
      '&searchUserId=' + searchUserId+
      '&userType=' + userType
    );
  }

  getOneStudentsTestAnswer(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'getOneStudentsTestAnswer', body);
  }


  deleteOrder(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'deleteOrder', body);
  }

  downloadExcelFile(
    start,
    aItemsPerPage,
    sort,
    type,
    searchId,
    searchName,
    searchStatus,
    searchDateFrom,
    searchDateTo,
    searchCountry,
    searchStage,
    searchGrade,
    searchSubject,
    searchTestSemester,
    searchUnit,
    searchLesson,
    searchUserId,
  userType) {
    return this.http.get<any>(
      this._url + 'downloadExcelFile&start=' + start +
      '&aItemsPerPage=' + aItemsPerPage +
      '&sort=' + sort +
      '&type=' + type +
      '&searchId=' + searchId +
      '&searchName=' + searchName +
      '&searchStatus=' + searchStatus +
      '&searchDateFrom=' + searchDateFrom +
      '&searchDateTo=' + searchDateTo +
      '&searchCountry=' + searchCountry +
      '&searchStage=' + searchStage +
      '&searchGrade=' + searchGrade +
      '&searchSubject=' + searchSubject +
      '&searchTestSemester=' + searchTestSemester +
      '&searchUnit=' + searchUnit +
      '&searchLesson=' + searchLesson+
      '&searchUserId=' + searchUserId+
      '&userType=' + userType
    );
  }

}


