import { Injectable } from '@angular/core';
// import { Http } from '@angular/http';
import { HttpClient } from '@angular/common/http';


import { CommonService } from '../../../../_services/common.service';

@Injectable()
export class CategoriesService {

    private _url: string = this.commonService._hostName + 'admin-api-v1/categories.php?action=';

    constructor(public http: HttpClient, public commonService: CommonService) { }

    getSomeCategories(start, aItemsPerPage, sort, type, searchTitle,category_type='article') {
        return this.http.get<any>(this._url + 'getSomeCategories&start=' + start + '&aItemsPerPage=' + aItemsPerPage + '&sort=' + sort + '&type=' + type + '&searchTitle=' + searchTitle+'&category_type=' + category_type);
    }

    getSearchCategoriesCount(sort, type, searchTitle,category_type='article') {
        return this.http.get<any>(this._url + 'getSearchCategoriesCount&sort=' + sort + '&type=' + type + '&searchTitle=' + searchTitle+'&category_type=' + category_type);
    }

    getCategoriesCount(category_type='article') {
        return this.http.get<any>(this._url + 'getCategoriesCount&category_type=' + category_type);
    }
 


    addEditCategory(data) {
        return this.http.post<any>(this._url + 'addEditCategory', data);
    }

    deletePage(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'deletePage', body);
    }
    deleteItems(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'deleteItems', body);
    }

    getOneCategory(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'getOneCategory', body);
    }

    activationCategories(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'toggleActivationCategories', body);
    }




}
