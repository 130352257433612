import { Injectable } from '@angular/core';
// import { Http } from '@angular/http';
import { HttpClient } from '@angular/common/http';


import { CommonService } from '../../../../_services/common.service';

@Injectable()
export class ReviewVideosService
{

    private _url: string = this.commonService._hostName + 'admin-api-v1/final_review_videos.php?action=';
    private user_id;
    constructor(public http: HttpClient, public commonService: CommonService) {
        var currentUser = localStorage.getItem('currentUser');
        var retrievedObject = JSON.parse(currentUser);
        this.user_id = retrievedObject.id;
     }

    getSomeVideos(start, aItemsPerPage, sort, type, searchTitle,searchCountry,searchStage,searchGrade,searchSemester,searchSubject,searchUnit,searchLesson,searchTeacherId,teacherName,searchDateFrom,searchDateTo,searchSource) {
        return this.http.get<any>(this._url + 'getSomeVideos&start=' + start + '&aItemsPerPage=' + aItemsPerPage + '&sort=' + sort + '&type=' + type + '&searchTitle=' + searchTitle+ '&searchCountry=' + searchCountry+ '&searchStage=' + searchStage+ '&searchGrade=' + searchGrade+ '&searchSemester=' + searchSemester+ '&searchSubject=' + searchSubject+ '&searchUnit=' + searchUnit+ '&searchLesson=' + searchLesson+ '&searchTeacherId=' + searchTeacherId+ '&teacherName=' + teacherName+ '&searchDateFrom=' + searchDateFrom+ '&searchDateTo=' + searchDateTo+ '&searchSource=' + searchSource+ '&user_id=' + this.user_id);
    }

    getSearchVideosCount(sort, type, searchTitle,searchCountry,searchStage,searchGrade,searchSemester,searchSubject,searchUnit,searchLesson,searchTeacherId,teacherName,searchDateFrom,searchDateTo,searchSource) {
        return this.http.get<any>(this._url + 'getSearchVideosCount&sort=' + sort + '&type=' + type + '&searchTitle=' + searchTitle+ '&searchCountry=' + searchCountry+ '&searchStage=' + searchStage+ '&searchGrade=' + searchGrade+ '&searchSemester=' + searchSemester+ '&searchSubject=' + searchSubject+ '&searchUnit=' + searchUnit+ '&searchLesson=' + searchLesson+ '&searchTeacherId=' + searchTeacherId+ '&teacherName=' + teacherName+ '&searchDateFrom=' + searchDateFrom+ '&searchDateTo=' + searchDateTo+ '&searchSource=' + searchSource);
    }

    getVideosCount() {
        return this.http.get<any>(this._url + 'getVideosCount');
    }



    getAllCategories(type='notes',subject_id='',branch_id='') {
        return this.http.get<any>(this._url + 'getAllCategories&type=' + type+'&subject_id=' + subject_id+'&branch_id=' + branch_id);
    }
    addEditVideos(data) {

        return this.http.post<any>(this._url + 'addEditVideos', data);
    }
    generateTestRequestPdf(data) {

        // return this.http.post<any>(this._url + 'generateTestRequestPdf',data,{ responseType: 'blob'});
    }

    uploadAttachment(data) {
      return this.http.post<any>(this._url + 'uploadAttachment', data, {
        reportProgress: true,
        observe: 'events'
      });
    }

    deleteAttachment(data) {
      return this.http.post<any>(this._url + 'deleteAttachment', data);
    }

    deleteVideo(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'deleteVideo', body);
    }







    getOneVideo(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'getOneTest', body);
    }
    getTestDetailsInfo(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'getTestDetailsInfo', body);
    }


    deleteVideoTests(data) {
      return this.http.post<any>(this._url + 'deleteVideoTests', data);
    }


    getCountCommentsInVideos(video_id: any, searchName: any) {
      return this.http.get<any>(
        this._url +
        'getCountCommentsInVideos&video_id=' +
        video_id +
        '&searchName=' +
        searchName
      );
    }


    getSearchCommentsCountVideos(
      video_id,
      sort,
      type,
      searchName,
      searchCountry
    ) {
      return this.http.get<any>(
        this._url +
        'getSearchCommentsCountVideos&video_id=' +
        video_id +
        '&sort=' +
        sort +
        '&type=' +
        type +
        '&searchName=' +
        searchName +
        '&searchCountry=' +
        searchCountry
      );
    }

    getSomeCommentsInVedios(
      video_id,
      start,
      aItemsPerPage,
      sort,
      type,
      searchName,
      searchCountry
    ) {
      return this.http.get<any>(
        this._url +
        'getSomeCommentsInVedios&video_id=' +
        video_id +
        '&start=' +
        start +
        '&aItemsPerPage=' +
        aItemsPerPage +
        '&sort=' +
        sort +
        '&type=' +
        type +
        '&searchName=' +
        searchName +
        '&searchCountry=' +
        searchCountry
      );
    }


    getCountInquiriesInVideos(video_id: any, searchName: any, searchUserName: any) {
      return this.http.get<any>(
        this._url +
        'getCountInquiriesInVideos&video_id=' +
        video_id +
        '&searchName=' +
        searchName +
        '&searchUserName=' +
        searchUserName
      );
    }

    getSearchInquiriesCountVideos(
      video_id,
      sort,
      type,
      searchName,
      searchUserName
    ) {
      return this.http.get<any>(
        this._url +
        'getSearchInquiriesCountVideos&video_id=' +
        video_id +
        '&sort=' +
        sort +
        '&type=' +
        type +
        '&searchName=' +
        searchName +
        '&searchUserName=' +
        searchUserName
      );
    }

    getSomeInquiriesInVedios(
      video_id,
      start,
      aItemsPerPage,
      sort,
      type,
      searchName,
      searchUserName
    ) {
      return this.http.get<any>(
        this._url +
        'getSomeInquiriesInVedios&video_id=' +
        video_id +
        '&start=' +
        start +
        '&aItemsPerPage=' +
        aItemsPerPage +
        '&sort=' +
        sort +
        '&type=' +
        type +
        '&searchName=' +
        searchName +
        '&searchUserName=' +
        searchUserName
      );
    }


    deleteCommentsInVideo(data) {
      let body = JSON.stringify(data);
      return this.http.post<any>(this._url + 'deleteCommentsInVideo', body);
    }

    updateCommentById(data) {
      let body = JSON.stringify(data);
      return this.http.post<any>(this._url + 'updateCommentById', body);
    }

    getAllInquiriesInVideoChat(data) {
      let body = JSON.stringify(data);
      return this.http.post<any>(this._url + "getAllInquiriesInVideoChat", body);
    }


    readInquiriesInVideoChat(data) {
      let body = JSON.stringify(data);
      return this.http.post<any>(this._url + "readInquiriesInVideoChat", body);
    }

    addInquiriesToVideoById(data) {
      let body = JSON.stringify(data);
      return this.http.post<any>(this._url + "addInquiriesToVideoById", body);
    }

    activateCommentsInVideo(data) {
      let body = JSON.stringify(data);
      return this.http.post<any>(this._url + 'activateCommentsInVideo', body);
    }

}


