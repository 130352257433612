import { Injectable } from '@angular/core';
// import { Http } from '@angular/http';
import { HttpClient } from '@angular/common/http';


import { CommonService } from '../../../../_services/common.service';

@Injectable()
export class ExamineQuestions {

    private _url: string = this.commonService._hostName + 'admin-api-v1/examine_questions.php?action=';
    private user_id;
    constructor(public http: HttpClient, public commonService: CommonService) {
        var currentUser:any = localStorage.getItem('currentUser');
        var retrievedObject = JSON.parse(currentUser);
        this.user_id = retrievedObject.id;
     }

    getSomeTests(start, aItemsPerPage, sort, type, searchTitle,searchCountry,searchStage,searchGrade,searchSemester,searchSubject,searchUnit,searchLesson,searchTeacherId,teacherName,searchDateFrom,searchDateTo,searchSource) {
        return this.http.get<any>(this._url + 'getSomeTests&start=' + start + '&aItemsPerPage=' + aItemsPerPage + '&sort=' + sort + '&type=' + type + '&searchTitle=' + searchTitle+ '&searchCountry=' + searchCountry+ '&searchStage=' + searchStage+ '&searchGrade=' + searchGrade+ '&searchSemester=' + searchSemester+ '&searchSubject=' + searchSubject+ '&searchUnit=' + searchUnit+ '&searchLesson=' + searchLesson+ '&searchTeacherId=' + searchTeacherId+ '&teacherName=' + teacherName+ '&searchDateFrom=' + searchDateFrom+ '&searchDateTo=' + searchDateTo+ '&searchSource=' + searchSource+ '&user_id=' + this.user_id);
    }

    getSearchTestsCount(sort, type, searchTitle,searchCountry,searchStage,searchGrade,searchSemester,searchSubject,searchUnit,searchLesson,searchTeacherId,teacherName,searchDateFrom,searchDateTo,searchSource) {
        return this.http.get<any>(this._url + 'getSearchTestsCount&sort=' + sort + '&type=' + type + '&searchTitle=' + searchTitle+ '&searchCountry=' + searchCountry+ '&searchStage=' + searchStage+ '&searchGrade=' + searchGrade+ '&searchSemester=' + searchSemester+ '&searchSubject=' + searchSubject+ '&searchUnit=' + searchUnit+ '&searchLesson=' + searchLesson+ '&searchTeacherId=' + searchTeacherId+ '&teacherName=' + teacherName+ '&searchDateFrom=' + searchDateFrom+ '&searchDateTo=' + searchDateTo+ '&searchSource=' + searchSource);
    }

    getTestsCount() {
        return this.http.get<any>(this._url + 'getTestsCount');
    }



    addEditQuestion(data) {

        return this.http.post<any>(this._url + 'addEditQuestion', data);
    }
    generateTestRequestPdf(data) {

        // return this.http.post<any>(this._url + 'generateTestRequestPdf',data,{ responseType: 'blob'});
    }

    uploadAttachment(data) {

        return this.http.post<any>(this._url + 'uploadAttachment', data);
    }

    deleteAttachment(data) {
        return this.http.post<any>(this._url + 'deleteAttachment', data);
    }

    deleteTest(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'deleteTest', body);
    }

    getQuestionsWithTestID(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'getQuestionsWithTestID', body);
    }
    deleteQuestion(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'deleteQuestion', body);
    }
    deleteSupQuestionByParent(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'deleteSupQuestionByParent', body);
    }
    deleteQuestionOption(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'deleteQuestionOption', body);
    }

    deleteMatchQuestionsItem(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'deleteMatchQuestionsItem', body);
    }
    deleteSortQuestionsWord(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'deleteSortQuestionsWord', body);
    }
    deleteCompleteQuestionsWord(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'deleteCompleteQuestionsWord', body);
    }
    getOneQuestion(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'getOneQuestion', body);
    }
    getTestDetailsInfo(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'getTestDetailsInfo', body);
    }


    addTestToExamin(data) {

        return this.http.post<any>(this._url + 'addTestToExamin', data);
    }

    getAllCategories(type='examine_questions',subject_id='', branch_id='') {
      return this.http.get<any>(this._url + 'getAllCategories&type=' + type+'&subject_id=' + subject_id + "&branch_id=" + branch_id);
  }


}
